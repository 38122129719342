import { ButtonHTMLAttributes, FunctionComponent } from "react";
import "./Button.scss";
import classNames from "classnames";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

interface ButtonProps {
  isLoading?: boolean;
}

export const Button: FunctionComponent<
  ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps
> = ({
  children,
  className,
  disabled = false,
  isLoading = false,
  ...props
}) => {
  return (
    <button
      className={classNames("Button", className)}
      type='button'
      disabled={disabled || isLoading}
      {...props}
    >
      {(!disabled || isLoading) && <div className='Button--spacer' />}
      <div className={classNames("Button--main")}>
        {isLoading ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          children
        )}
      </div>
    </button>
  );
};
