import { backgroundTranslation } from "context/atoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

export const useControlBackgroundPosition = (
  location: string,
  shouldTransition: boolean
) => {
  const [showIsland, setShowIsland] = useState(false);
  const [_, setTranslation] = useAtom(backgroundTranslation);

  useEffect(() => {
    setShowIsland(true);
    setTranslation(location);

    if (!shouldTransition) {
      document
        .getElementsByTagName("img")[0]
        .classList.add("App__background--no-transition");
      document.getElementsByTagName("img")[0].style.translate = location;
    } else {
      document
        .getElementsByTagName("img")[0]
        .classList.remove("App__background--no-transition");
    }
  }, []);

  return [showIsland];
};
