import classNames from "classnames";
import { FunctionComponent, PropsWithChildren, useEffect, useRef } from "react";
import "./Blob.scss";
import Section from "components/Section/Section";

interface BlobProps {
  width?: string;
  height?: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void;
}

export const Blob: FunctionComponent<PropsWithChildren<BlobProps>> = ({
  children,
  width = "27rem",
  height = "40rem",
  className,
  onClick,
}) => {
  const blobRef = useRef<HTMLDivElement>(null);

  const getRandomPercentage = () =>
    Math.floor(Math.random() * (100 - 20) + 20) + "%";

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (blobRef.current) {
      const intervalFun = () => {
        if (blobRef.current?.style) {
          blobRef.current.style.borderRadius = `${getRandomPercentage()} ${getRandomPercentage()} ${getRandomPercentage()} ${getRandomPercentage()} / ${getRandomPercentage()} ${getRandomPercentage()} ${getRandomPercentage()} ${getRandomPercentage()} `;
        }
      };
      intervalFun();
      interval = setInterval(intervalFun, 1000 * 2);
    }
    return () => {
      clearInterval(interval);
    };
  }, [blobRef.current]);

  return (
    <div className={classNames("Blob", className)}>
      <div
        ref={blobRef}
        className={classNames(
          "Blob__blob",
          "Blob__blob--no-transition",
          onClick && "Blob__blob--clickable"
        )}
        style={{ width, height }}
        onClick={onClick}
      >
        <Section>{children}</Section>
      </div>
    </div>
  );
};
