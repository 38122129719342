import { TFunction } from "i18next";
import { object, string } from "yup";

export const validateForm = (
  t: TFunction<"translation", undefined, "translation">
) =>
  object({
    name: string().required(t("contactPage.fields.name.required") ?? ""),
    email: string()
      .email(t("contactPage.fields.name.valid") ?? "")
      .required(t("contactPage.fields.email.required") ?? ""),
    phone: string().optional(),
    message: string().required(t("contactPage.fields.message.required") ?? ""),
  });
