import {
  DetailedHTMLProps,
  FunctionComponent,
  InputHTMLAttributes,
  createElement,
} from "react";
import "./Input.scss";
import classNames from "classnames";
import Typography from "components/Typography/Typography";

interface InputProps {
  label?: string;
  supportText?: string;
  as?: "input" | "textarea";
  isError?: boolean;
  labelClassName?: string;
}

const Input: FunctionComponent<
  InputProps &
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({
  label,
  isError = false,
  supportText,
  className,
  labelClassName,
  as = "input",
  ...props
}) => {
  return (
    <div
      className={classNames("Input", labelClassName, isError && "Input--error")}
    >
      {Boolean(label) && (
        <Typography as='h4' className='Input__Label'>
          {label}
        </Typography>
      )}
      {createElement(as, {
        className: classNames(
          "Input__Element",
          className,
          as === "textarea" && "Input__Element--textarea"
        ),
        ...props,
      })}
      {Boolean(supportText) && (
        <Typography format='mc' className='Input__SupportText'>
          {supportText}
        </Typography>
      )}
    </div>
  );
};

export default Input;
