import classNames from "classnames";
import "./AboutMe.scss";
import { FunctionComponent, useState } from "react";
import {
  aboutMePosition,
  homePosition,
  skillsPosition,
} from "constants/backgroundImagePositions";

import { useControlBackgroundPosition } from "common/hooks/useControlBackgroundPosition";
import { Blob } from "components/Blob/Blob";
import Typography from "components/Typography/Typography";
import { Button } from "components/Button/Button";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { home, skills } from "routes/paths";
import { delayRedirect } from "common/delayRedirect";
import { useAtom } from "jotai";
import { backgroundTranslation, shouldLoadPage } from "context/atoms";
import { Skills } from "modules/Skills/Skills";
import Home from "modules/Home/Home";

interface AboutMeProps {
  shouldTransition?: boolean;
}

export const AboutMe: FunctionComponent<AboutMeProps> = ({
  shouldTransition = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [move, shouldMove] = useState(false);
  const [moveToHome, shouldMoveToHome] = useState(false);
  const [_, setTranslation] = useAtom(backgroundTranslation);
  const [loadPage, setLoadPage] = useAtom(shouldLoadPage);
  const [showIsland] = useControlBackgroundPosition(
    aboutMePosition,
    shouldTransition
  );

  const moveToIsland = (isHome = false) => {
    if (isHome) {
      shouldMoveToHome(true);
    } else {
      shouldMove(true);
    }
    document
      .getElementsByTagName("img")[0]
      .classList.remove("App__background--no-transition");
    if (isHome) {
      setTranslation(homePosition);
    } else {
      setTranslation(skillsPosition);
    }
  };

  return (
    <>
      <div
        className={classNames(
          "AboutMe",
          (shouldTransition || move || moveToHome) && "AboutMe--transition",
          !shouldTransition && !move && !moveToHome && "AboutMe--no-transition",
          showIsland && "AboutMe--move",
          move && "AboutMe--move-to-new-area",
          moveToHome && "AboutMe--move-to-new-area-home",
          loadPage && "AboutMe--load-page"
        )}
      >
        <div>
          <Blob>
            <div className='AboutMe__textContainer'>
              <Typography format='dts' className='AboutMe__text'>
                {t("aboutMePage.title")}
              </Typography>
            </div>
            <Typography className='AboutMe__text'>
              {t("aboutMePage.desc")}
            </Typography>
          </Blob>
          <div className='AboutMe__btn'>
            <Link
              to={skills}
              onClick={(e) => {
                setLoadPage(false);
                delayRedirect(e, navigate, skills);
                moveToIsland();
              }}
            >
              <Button>
                <Typography>{t("aboutMePage.buttons.skills")}</Typography>
              </Button>
            </Link>
            <Link
              to={home}
              onClick={(e) => {
                setLoadPage(false);
                delayRedirect(e, navigate, home);
                moveToIsland(true);
              }}
            >
              <Button>
                <Typography>{t("aboutMePage.buttons.home")}</Typography>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      {move && <Skills shouldTransition translateX={-100} translateY={40} />}
      {moveToHome && <Home shouldTransition translateX={0} translateY={105} />}
    </>
  );
};
