import { useControlBackgroundPosition } from "common/hooks/useControlBackgroundPosition";
import "./Skills.scss";
import {
  homePosition,
  skillsPosition,
} from "constants/backgroundImagePositions";
import { FunctionComponent, useState } from "react";
import classNames from "classnames";
import { Blob } from "components/Blob/Blob";
import Typography from "components/Typography/Typography";
import { Button } from "components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { home } from "routes/paths";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { backgroundTranslation, shouldLoadPage } from "context/atoms";
import { delayRedirect } from "common/delayRedirect";
import Home from "modules/Home/Home";

interface SkillsProps {
  shouldTransition?: boolean;
  translateX?: number;
  translateY?: number;
}

export const Skills: FunctionComponent<SkillsProps> = ({
  shouldTransition = false,
  translateX = -200,
  translateY = 100,
}) => {
  const { t } = useTranslation();
  const [_, setTranslation] = useAtom(backgroundTranslation);
  const [loadPage, setLoadPage] = useAtom(shouldLoadPage);
  const [move, shouldMove] = useState(false);
  const navigate = useNavigate();
  const [showIsland] = useControlBackgroundPosition(
    skillsPosition,
    shouldTransition
  );

  const moveToIsland = () => {
    shouldMove(true);
    document
      .getElementsByTagName("img")[0]
      .classList.remove("App__background--no-transition");
    setTranslation(homePosition);
  };

  const BLOB_WIDTH = "11rem";
  const BLOB_HEIGHT = "11rem";

  const style = {
    "--translate": `${translateX}% ${translateY}%`,
  } as React.CSSProperties;

  return (
    <>
      <div
        className={classNames(
          "Skills",
          shouldTransition || (move && "Skills--transition"),
          !shouldTransition && !move && "Skills--no-transition",
          showIsland && "Skills--move",
          loadPage && "Skills--load-page",
          move && "Skills--move-new-area"
        )}
        style={style}
      >
        <div className='Skills__container'>
          <Typography format='dts'>{t("skillsPage.title")}</Typography>
          <div className='Skills__blob-container'>
            <Blob
              width={BLOB_WIDTH}
              height={BLOB_HEIGHT}
              className='Skills__blob'
            >
              <Typography format='ts' className='Skills__small-text'>
                React | Javascript | Node.Js | EsLint | NPM
              </Typography>
            </Blob>
            <Blob
              width={BLOB_WIDTH}
              height={BLOB_HEIGHT}
              className='Skills__blob'
            >
              <Typography format='ts'>
                Java | Spring Boot | Kafka | C# | Kotlin
              </Typography>
            </Blob>
            <Blob
              width={BLOB_WIDTH}
              height={BLOB_HEIGHT}
              className='Skills__blob'
            >
              <Typography format='ts'>Postgres | SQL</Typography>
            </Blob>
            <Blob
              width={BLOB_WIDTH}
              height={BLOB_HEIGHT}
              className='Skills__blob'
            >
              <Typography format='ts'>Amazon Web Services (AWS)</Typography>
            </Blob>
            <Blob
              width={BLOB_WIDTH}
              height={BLOB_HEIGHT}
              className='Skills__blob'
            >
              <Typography format='ts'>JEST | Cypress MockMVC | Pact</Typography>
            </Blob>
            <Blob
              width={BLOB_WIDTH}
              height={BLOB_HEIGHT}
              className='Skills__blob'
            >
              <Typography format='ts'>Circle CI | Sleuth</Typography>
            </Blob>{" "}
            <Blob
              width={BLOB_WIDTH}
              height={BLOB_HEIGHT}
              className='Skills__blob'
            >
              <Typography format='ts'>Datadog | Kubernetes | Docker</Typography>
            </Blob>
            <Blob
              width={BLOB_WIDTH}
              height={BLOB_HEIGHT}
              className='Skills__blob'
            >
              <Typography format='ts'>Linux | Windows | IOs</Typography>
            </Blob>
            <Blob
              width={BLOB_WIDTH}
              height={BLOB_HEIGHT}
              className='Skills__blob'
            >
              <Typography format='ts'>Mapbox | ChartJS | Nivo</Typography>
            </Blob>
            <Blob
              width={BLOB_WIDTH}
              height={BLOB_HEIGHT}
              className='Skills__blob'
            >
              <Typography format='ts'>
                Bitbucket | Jira | Confluence Github
              </Typography>
            </Blob>
          </div>
          <Link
            to={home}
            onClick={(e) => {
              setLoadPage(false);
              delayRedirect(e, navigate, home);
              moveToIsland();
            }}
          >
            <Button>
              <Typography>{t("skillsPage.buttons.home")}</Typography>
            </Button>
          </Link>
        </div>
      </div>
      {move && <Home shouldTransition translateX={110} translateY={80} />}
    </>
  );
};
