import "./Home.scss";
import { FunctionComponent, useState } from "react";
import { useAtom } from "jotai";
import { backgroundTranslation, shouldLoadPage } from "context/atoms";
import {
  aboutMePosition,
  contactPosition,
  landingPosition,
  skillsPosition,
} from "constants/backgroundImagePositions";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { aboutMe, contact, skills } from "routes/paths";
import { delayRedirect } from "common/delayRedirect";
import { AboutMe } from "modules/AboutMe/AboutMe";
import { useControlBackgroundPosition } from "common/hooks/useControlBackgroundPosition";
import { useTranslation } from "react-i18next";
import Typography from "components/Typography/Typography";
import { Skills } from "modules/Skills/Skills";
import { ChangeLanguageButton } from "modules/ChangeLanguageButton/ChangeLanguageButton";
import { Button } from "components/Button/Button";
import ContactForm from "modules/Contact/ContactForm";

interface HomeProps {
  shouldTransition?: boolean;
  translateX?: number;
  translateY?: number;
}

type IslandType = "skills" | "aboutMe" | "contact";

const Home: FunctionComponent<HomeProps> = ({
  shouldTransition = false,
  translateX = -70,
  translateY = 0,
}) => {
  const [_, setTranslation] = useAtom(backgroundTranslation);
  const [loadPage, setLoadPage] = useAtom(shouldLoadPage);
  const navigate = useNavigate();
  const [move, shouldMove] = useState(false);
  const [moveToSkills, shouldMoveToSkills] = useState(false);
  const [moveToContact, shouldMoveToContact] = useState(false);

  const { t } = useTranslation();

  const [showIsland] = useControlBackgroundPosition(
    landingPosition,
    shouldTransition
  );

  const moveToIsland = (islandType: IslandType = "aboutMe") => {
    if (islandType === "skills") {
      shouldMoveToSkills(true);
    } else if (islandType === "contact") {
      shouldMoveToContact(true);
    } else {
      shouldMove(true);
    }
    document
      .getElementsByTagName("img")[0]
      .classList.remove("App__background--no-transition");
    if (islandType === "skills") {
      setTranslation(skillsPosition);
    } else if (islandType === "contact") {
      setTranslation(contactPosition);
    } else {
      setTranslation(aboutMePosition);
    }
  };

  const style = {
    "--translate": `${translateX}% ${translateY}%`,
  } as React.CSSProperties;

  return (
    <>
      <div
        className={classNames({
          Home: true,
          "Home--transition":
            shouldTransition || move || moveToSkills || moveToContact,
          "Home--no-transition":
            !shouldTransition && !move && !moveToSkills && !moveToContact,
          "Home--move": showIsland,
          "Home--move-new-area": move,
          "Home--move-new-area-skills": moveToSkills,
          "Home--load-page": loadPage,
          "Home--move-new-area-contact": moveToContact,
        })}
        style={style}
      >
        <div className={"Home__container"}>
          <Link
            to={aboutMe}
            onClick={(e) => {
              setLoadPage(false);
              delayRedirect(e, navigate, aboutMe);
              moveToIsland();
            }}
          >
            <Button>
              <Typography format='ts'>{t("home.buttons.aboutMe")}</Typography>
            </Button>
          </Link>

          <Link
            to={skills}
            onClick={(e) => {
              setLoadPage(false);
              delayRedirect(e, navigate, skills);
              moveToIsland("skills");
            }}
          >
            <Button>
              <Typography format='ts'>{t("home.buttons.skills")}</Typography>
            </Button>
          </Link>

          <Link
            to={contact}
            onClick={(e) => {
              setLoadPage(false);
              delayRedirect(e, navigate, contact);
              moveToIsland("contact");
            }}
          >
            <Button>
              <Typography format='ts'>{t("home.buttons.contact")}</Typography>
            </Button>
          </Link>

          <ChangeLanguageButton large />
        </div>
      </div>
      {move && <AboutMe shouldTransition />}
      {moveToSkills && (
        <Skills shouldTransition translateX={-120} translateY={-70} />
      )}
      {moveToContact && (
        <ContactForm shouldTransition translateX={-100} translateY={0} />
      )}
    </>
  );
};

export default Home;
