import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import TRANSLATION_EN_JSON from "locales/en/translation.json";
import TRANSLATION_GR_JSON from "locales/gr/translation.json";
import { Languages } from "config/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resources: Record<Languages, any> = {
  en: {
    translation: { ...TRANSLATION_EN_JSON },
  },
  gr: {
    translation: { ...TRANSLATION_GR_JSON },
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
