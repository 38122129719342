import "./LoadingSpinner.scss";

const LoadingSpinner = () => (
  <div className='lds-ellipsis'>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default LoadingSpinner;
