import Input from "components/Input/Input";
import "./ContactForm.scss";
import Section from "components/Section/Section";
import Typography from "components/Typography/Typography";
import { Button } from "components/Button/Button";
import { FunctionComponent, useState } from "react";
import { validateForm } from "modules/Contact/ContactForm.util";
import { ValidationError } from "yup";
import { useControlBackgroundPosition } from "common/hooks/useControlBackgroundPosition";
import {
  contactPosition,
  homePosition,
} from "constants/backgroundImagePositions";
import classNames from "classnames";
import { useAtom } from "jotai";
import { backgroundTranslation, shouldLoadPage } from "context/atoms";
import Home from "modules/Home/Home";
import { delayRedirect } from "common/delayRedirect";
import { useNavigate } from "react-router-dom";
import { home } from "routes/paths";
import { usePostContacts } from "api/hooks/usePostContacts";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

interface ContactFormProps {
  shouldTransition?: boolean;
  translateX?: number;
  translateY?: number;
}

const ContactForm: FunctionComponent<ContactFormProps> = ({
  shouldTransition = false,
  translateX = -70,
  translateY = -70,
}) => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = usePostContacts();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formValid, setFormValid] = useState(false);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [move, shouldMove] = useState(false);
  const [loadPage, setLoadPage] = useAtom(shouldLoadPage);
  const [_, setTranslation] = useAtom(backgroundTranslation);
  const [showIsland] = useControlBackgroundPosition(
    contactPosition,
    shouldTransition
  );

  const checkFormValidation = (path: string) => {
    validateForm(t)
      .validate({ name, email, phone, message })
      .then(() => {
        setFormValid(true);
        setErrors({ name: "", email: "", phone: "", message: "" });
      })
      .catch(() => {
        validateForm(t)
          .validateAt(path, { name, email, phone, message })
          .then(() => {
            setErrors({ ...errors, [path]: "" });
          })
          .catch((e: ValidationError) => {
            setErrors({ ...errors, [path]: e.message });
          });
        setFormValid(false);
      });
  };

  const moveToIsland = () => {
    shouldMove(true);
    document
      .getElementsByTagName("img")[0]
      .classList.remove("App__background--no-transition");
    setTranslation(homePosition);
  };

  const style = {
    "--translate": `${translateX}% ${translateY}%`,
  } as React.CSSProperties;

  const createContactCall = () => {
    setErrorMessage(undefined);
    setSuccessfullySubmitted(false);
    mutateAsync({
      values: {
        name,
        email,
        phoneNumber: phone,
        message,
      },
    })
      .then(() => setSuccessfullySubmitted(true))
      .catch((e: AxiosError<any>) => {
        const errorMessage =
          e.response?.data?.message ??
          "Something went wrong. Your email could not be sent./n Please try again";
        setErrorMessage(errorMessage);
      });
  };

  return (
    <>
      <div
        className={classNames(
          "ContactForm",
          showIsland && "ContactForm--move",
          (shouldTransition || move) && "ContactForm--transition",
          !shouldTransition && !move && "ContactForm--no-transition",
          loadPage && "ContactForm--load-page",
          move && "ContactForm--home-position"
        )}
        style={style}
      >
        <Section classname='ContactForm__Section'>
          <div className='ContactForm__SectionContainer'>
            <Typography className='ContactForm__Title' format='dts'>
              {t("contactPage.title")}
            </Typography>
            <Input
              labelClassName='ContactForm__Input'
              placeholder={t("contactPage.fields.name.placeholder") as string}
              label={t("contactPage.fields.name.label") as string}
              value={name}
              onChange={(e) => {
                setName(e.currentTarget.value);
              }}
              onBlur={() => checkFormValidation("name")}
              supportText={errors.name}
              isError={Boolean(errors.name)}
            />
            <Input
              labelClassName='ContactForm__Input'
              placeholder={t("contactPage.fields.email.placeholder") as string}
              label={t("contactPage.fields.email.label") as string}
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
              onBlur={() => checkFormValidation("email")}
              supportText={errors.email}
              isError={Boolean(errors.email)}
            />
            <Input
              labelClassName='ContactForm__Input'
              placeholder={t("contactPage.fields.phone.placeholder") as string}
              label={t("contactPage.fields.phone.label") as string}
              value={phone}
              onChange={(e) => {
                setPhone(e.currentTarget.value);
              }}
              onBlur={() => checkFormValidation("phone")}
              supportText={errors.phone}
              isError={Boolean(errors.phone)}
            />
            <Input
              labelClassName='ContactForm__Input'
              placeholder={
                t("contactPage.fields.message.placeholder") as string
              }
              label={t("contactPage.fields.message.label") as string}
              as='textarea'
              value={message}
              onChange={(e) => {
                setMessage(e.currentTarget.value);
              }}
              onBlur={() => checkFormValidation("message")}
              supportText={errors.message}
              isError={Boolean(errors.message)}
            />
            <Button
              disabled={!formValid}
              onClick={createContactCall}
              isLoading={isLoading}
            >
              <Typography>{t("contactPage.submit")}</Typography>
            </Button>
          </div>
          <Button
            onClick={(e) => {
              setLoadPage(false);
              delayRedirect(e, navigate, home);
              moveToIsland();
            }}
            className='ContactForm__CloseBtn'
          ></Button>
          {successfullySubmitted && (
            <Typography style={{ textAlign: "center" }}>
              {t("contactPage.successfulMessage")}
            </Typography>
          )}
          {Boolean(errorMessage) && (
            <Typography style={{ textAlign: "center", color: "#ac2828" }}>
              {errorMessage}
            </Typography>
          )}
        </Section>
      </div>
      {move && <Home shouldTransition translateX={90} translateY={0} />}
    </>
  );
};

export default ContactForm;
