import classNames from "classnames";
import { delayRedirect } from "common/delayRedirect";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { home } from "routes/paths";
import "./LandingPage.scss";
import Typography from "components/Typography/Typography";
import { format } from "date-fns";
import { enGB, el } from "date-fns/locale";
import { Blob } from "components/Blob/Blob";
import { useTranslation } from "react-i18next";
import { Languages } from "config/types";
import { Button } from "components/Button/Button";
import { backgroundTranslation, shouldLoadPage } from "context/atoms";
import { useAtom } from "jotai";
import {
  homePosition,
  landingPosition,
} from "constants/backgroundImagePositions";
import Home from "modules/Home/Home";
import { ChangeLanguageButton } from "modules/ChangeLanguageButton/ChangeLanguageButton";

function LandingPage() {
  const [move, shouldMove] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [translation, setTranslation] = useAtom(backgroundTranslation);
  const [_, setLoadPage] = useAtom(shouldLoadPage);

  const dateLanguage: Record<Languages, Locale> = {
    en: enGB,
    gr: el,
  };

  const moveToIsland = () => {
    shouldMove(true);
    setTranslation(landingPosition);
  };

  useEffect(() => {
    setTranslation(homePosition);
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentDateTime(now);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='LandingPage'>
      <div
        className={classNames({
          LandingPage__container: true,
          "LandingPage__container--move": move,
        })}
      >
        <Blob>
          <>
            <div className='LandingPage__link-container'>
              <Typography format='bcs' className='LandingPage__link-clock'>
                {format(currentDateTime, "eeee, do MMMM yyyy", {
                  locale: dateLanguage[i18n.language as Languages],
                })}
                {" - "}
                {currentDateTime.toLocaleTimeString()}
              </Typography>
              <div className='LandingPage__link-title-container'>
                <Typography className='LandingPage__link-title' format='tl'>
                  {t("landingPage.title")}
                </Typography>
              </div>
              <div className='LandingPage__link-title-container LandingPage__link-title-container--small'>
                <Typography className='LandingPage__link-desc' format='bcl'>
                  {t("landingPage.desc")}
                </Typography>
              </div>
              <div className='LandingPage__btns'>
                <Link
                  to={home}
                  onClick={(e) => {
                    setLoadPage(false);
                    delayRedirect(e, navigate, home);
                    moveToIsland();
                  }}
                >
                  <Button>
                    <Typography>{t("landingPage.buttons.home")}</Typography>
                  </Button>
                </Link>
                <ChangeLanguageButton />
              </div>
            </div>
          </>
        </Blob>
      </div>
      {move && <Home shouldTransition translateX={70} translateY={70} />}
    </div>
  );
}

export default LandingPage;
