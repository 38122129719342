import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import "./Section.scss";

interface SectionProps {
  classname?: string;
  color?:
    | "white"
    | "blue"
    | "orange"
    | "yellow"
    | "beige"
    | "brown"
    | "black"
    | "transparent";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Section: FC<PropsWithChildren<SectionProps>> = ({
  children,
  classname,
  color = "transparent",
}) => {
  return (
    <section className={classNames(["Section", classname])}>
      <div
        className={classNames([
          "Section__container",
          `Section__container--${color}`,
        ])}
      >
        {children}
      </div>
    </section>
  );
};

export default Section;
