import Home from "modules/Home/Home";
import { Route, Routes } from "react-router-dom";
import { aboutMe, contact, home, root, skills } from "routes/paths";
import LandingPage from "../modules/LandingPage/LandingPage";
import { AboutMe } from "modules/AboutMe/AboutMe";
import { Skills } from "modules/Skills/Skills";
import ContactForm from "modules/Contact/ContactForm";

const Main = () => {
  return (
    <Routes>
      <Route index path={root} element={<LandingPage />} />
      <Route path={home} element={<Home />} />
      <Route path={aboutMe} element={<AboutMe />} />
      <Route path={skills} element={<Skills />} />
      <Route path={contact} element={<ContactForm />} />
      <Route path='*' element={<LandingPage />} />
    </Routes>
  );
};

export default Main;
