import { axiosInstance } from "api/axiosConfig";
import { POSTContacts } from "api/types";

export const getContacts = async () => {
  const { data } = await axiosInstance.get("/contacts");
  return data;
};

export const postContact = async (body: POSTContacts) => {
  const { data } = await axiosInstance.post("/contacts", body);
  return data;
};
