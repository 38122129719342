import { FunctionComponent, PropsWithChildren, useEffect } from "react";
import s from "./assets/images/background-island.jpg";
import "./App.scss";
import { useAtom } from "jotai";
import { backgroundTranslation } from "context/atoms";
import { useTranslation } from "react-i18next";
export const App: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [currentTranslationPosition] = useAtom(backgroundTranslation);
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = window.sessionStorage.getItem("lang") ?? "en";
    i18n.changeLanguage(lang);
  }, []);

  return (
    <div className='App'>
      <div className='App__overlay' />
      <img
        className='App__background'
        src={s}
        alt='Background image'
        style={{ translate: currentTranslationPosition }}
      ></img>
      {children}
    </div>
  );
};
