import { NavigateFunction } from "react-router-dom";

export const delayRedirect = (
  e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
  navigate: NavigateFunction,
  route: string
) => {
  e.preventDefault();
  setTimeout(() => navigate(route), 700);
};
