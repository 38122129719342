import { Button } from "components/Button/Button";
import Typography from "components/Typography/Typography";
import { Languages } from "config/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ChangeLanguageButtonProps {
  large?: boolean;
}

export const ChangeLanguageButton: FunctionComponent<
  ChangeLanguageButtonProps
> = ({ large = false }) => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = i18n.language as Languages;
  const switchLang = (lang: Languages) => (lang === "en" ? "gr" : "en");

  return (
    <Button
      onClick={() => {
        const newLang = switchLang(selectedLanguage);
        i18n.changeLanguage(newLang);
        window.sessionStorage.setItem("lang", newLang);
      }}
    >
      <Typography format={large ? "ts" : "bcm"}>
        {t(
          `landingPage.buttons.languages.${
            selectedLanguage === "en" ? "gr" : "en"
          }`
        )}
      </Typography>
    </Button>
  );
};
