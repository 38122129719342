import classnames from "classnames";
import { FunctionComponent, createElement } from "react";
import "./Typography.scss";
import { TypographyProps } from "components/Typography/types";
import { useTranslation } from "react-i18next";
import { Languages } from "config/types";

const Typography: FunctionComponent<TypographyProps> = ({
  children,
  className,
  format = "bcm",
  as = "p",
  weight = "normal",
  ...otherProps
}) => {
  const { i18n } = useTranslation();
  const language = i18n.language as Languages;
  return createElement(
    as,
    {
      ...otherProps,
      className: classnames(
        "Typography",
        `Typography--${format}`,
        `Typography--${format}-${language}`,
        `Typography--${weight}`,
        className
      ),
    },
    children
  );
};

export default Typography;
