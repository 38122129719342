import { postContact } from "api/client";
import { POST_CONTACTS_KEY } from "api/hooks/queryKeys";
import { POSTContacts } from "api/types";
import { useMutation } from "react-query";

export const usePostContacts = () => {
  return useMutation(
    POST_CONTACTS_KEY,
    async (data: { values: POSTContacts }) => {
      return postContact(data.values);
    }
  );
};
